<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.usersByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="headers === 'master-user' ? masterUsersPageHeaders : headers === 'user' ? usersPageHeaders : ''"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="users"
            :search="searchString"
            :item-class="addCustomClass"
            class="elevation-1"
            @click:row="editItem"
            multi-sort>
            <template v-slot:item.role.name="{ item }">
              {{ getTranslatedRole(item.role) }}
            </template>

            <template v-slot:item.username="{ item }">
              {{ item.role.id !== 7 ? item.username : '' }}
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ clientId ? '' : saasPage ? $t('tables.saasAdministrators') : $t('tables.users') }}
                </v-toolbar-title>

                <v-divider v-if="!clientId" class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer />

                <v-dialog v-model="dialog" max-width="700px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('buttons.add') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(formTitle) }}
                      <v-spacer />
                      <v-icon class="mdi mdi-close" style="color: white" @click="close"></v-icon>
                    </v-card-title>
                    <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                      <v-form lazy-validation @submit.prevent="handleSubmit(save)">
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" hidden md="6" sm="12">
                                <v-text-field v-model="editedItem.id" :label="$t('usersPage.id')"></v-text-field>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider rules="required|max:200" v-slot="{ errors }" name="first_name">
                                  <v-text-field
                                    v-model="editedItem.first_name"
                                    autofocus
                                    name="first_name"
                                    clearable
                                    counter="200"
                                    :label="$t('usersPage.firstName')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider rules="required|max:200" v-slot="{ errors }" name="last_name">
                                  <v-text-field
                                    v-model="editedItem.last_name"
                                    name="last_name"
                                    clearable
                                    counter="200"
                                    :label="$t('usersPage.lastName')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12" v-if="editedItem.role_id !== 7">
                                <validation-provider
                                  rules="required|max:200"
                                  v-slot="{ errors }"
                                  name="username"
                                  ref="usernameRef">
                                  <v-text-field
                                    v-model="editedItem.username"
                                    name="username"
                                    clearable
                                    counter="200"
                                    :label="$t('usersPage.username')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12" v-if="editedItem.role_id !== 7">
                                <validation-provider
                                  :rules="editedItem.id === null ? 'required|min:6|max:50' : 'min:6'"
                                  v-slot="{ errors }"
                                  name="password">
                                  <v-text-field
                                    v-model="editedItem.password"
                                    :required="editedItem.id === null"
                                    :type="showPassword ? 'text' : 'password'"
                                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                    @click:append="togglePassword"
                                    name="password"
                                    clearable
                                    counter="50"
                                    :label="$t('usersPage.password')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                  :rules="{
                                    max: 200,
                                    required: true,
                                    regex: /^\+?[0-9\s\-()]+$/,
                                  }"
                                  v-slot="{ errors }"
                                  name="phone_number">
                                  <v-text-field
                                    v-model="editedItem.phone"
                                    name="phone"
                                    clearable
                                    counter="200"
                                    :label="$t('usersPage.phoneNumber')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col v-if="!saasPage" cols="12" lg="6" md="6" sm="12">
                                <validation-provider rules="required" v-slot="{ errors }" name="role">
                                  <v-autocomplete
                                    v-model="editedItem.role_id"
                                    clearable
                                    :items="roles"
                                    item-text="name"
                                    item-value="id"
                                    :label="$t('usersPage.role')"
                                    name="role_id"
                                    :disabled="clientId && editedItem.role_id == 11"
                                    :error-messages="errors"
                                    :no-data-text="$t('select.noDataAvailable')"
                                    @focus="$event.target.click()"></v-autocomplete>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12" v-if="!clientId && editedItem.role_id == 11">
                                <validation-provider rules="required" v-slot="{ errors }" name="client">
                                  <v-autocomplete
                                    v-model="editedItem.client_id"
                                    :items="clients"
                                    clearable
                                    item-text="name"
                                    item-value="id"
                                    :label="$t('usersPage.client')"
                                    :no-data-text="$t('select.noDataAvailable')"
                                    @focus="$event.target.click()"
                                    :error-messages="errors"></v-autocomplete>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                  rules="email|max:200"
                                  v-slot="{ errors }"
                                  name="email"
                                  ref="emailRef">
                                  <v-text-field
                                    v-model="editedItem.email"
                                    name="email"
                                    clearable
                                    counter="200"
                                    :label="$t('usersPage.email')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider rules="image|size:4000" v-slot="{ errors }" name="image">
                                  <v-file-input
                                    v-model="editedItem.image"
                                    accept="image/jpg, image/jpeg, image/png"
                                    counter
                                    :label="$t('usersPage.image')"
                                    prepend-icon="mdi-camera"
                                    show-size
                                    small-chips
                                    :error-messages="errors"></v-file-input>
                                </validation-provider>
                              </v-col>
                              <v-col cols="6" md="6" sm="12">
                                <validation-provider rules="" v-slot="{ errors }" name="time_zone">
                                  <v-autocomplete
                                    v-model="editedItem.timezone"
                                    :items="timeZones"
                                    clearable
                                    item-text="name"
                                    item-value="name"
                                    :label="$t('organisationsPage.timeZone')"
                                    required
                                    :no-data-text="$t('select.noDataAvailable')"
                                    @focus="$event.target.click()"
                                    :error-messages="errors"></v-autocomplete>
                                </validation-provider>
                              </v-col>
                              <v-col
                                v-if="parseInt(editedItem.role_id) === 2 || parseInt(editedItem.role_id) === 3"
                                lg="2"
                                md="3">
                                <validation-provider
                                  :rules="
                                    editedItem.percentage
                                      ? 'numeric_decimal|min_value:0|max_value:100'
                                      : 'numeric_decimal|min_value:0|max_value:100'
                                  "
                                  v-slot="{ errors }"
                                  name="percentage">
                                  <v-text-field
                                    v-model="editedItem.percentage"
                                    name="percentage"
                                    clearable
                                    :label="$t('usersPage.percentage')"
                                    type="text"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col v-if="parseInt(editedItem.role_id) === 3" lg="10" md="9">
                                <v-checkbox
                                  v-model="editedItem.passengers_details_visible"
                                  :label="$t('usersPage.passengersInfoAvailableToDriver')"></v-checkbox>
                              </v-col>
                            </v-row>

                            <v-row v-if="[2, 3, 7].includes(editedItem.role_id)">
                              <v-col cols="12" lg="12" md="12">
                                <hr class="line" />
                              </v-col>
                            </v-row>

                            <v-row
                              v-if="[2, 3, 7].includes(editedItem.role_id)"
                              class="d-flex flex-column-reverse flex-sm-row">
                              <v-col cols="12" lg="6" sm="6">
                                <v-color-picker
                                  v-model="editedItem.color"
                                  dot-size="10"
                                  hide-mode-switch
                                  mode="hexa"></v-color-picker>
                              </v-col>
                              <v-col cols="12" lg="6" sm="6">
                                <v-label class="d-block mb-3">
                                  {{ $t('usersPage.colorPicker') }}
                                </v-label>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer />
                          <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                        </v-card-actions>
                      </v-form>
                    </validation-observer>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn }">
                      <v-btn text v-on="{ ...on, ...tooltipOn }">
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('buttons.actions') }}
                    </span>
                  </v-tooltip>
                </template>

                <v-list>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="editItem(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.edit') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.edit') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="showProfilePicture(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-fit-to-screen</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.showImage') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.showImage') }}</span>
                  </v-tooltip>

                  <!-- We need seperate tooltips for activation and deactivation, because we have special case 
                  for deactivation where we must provide special tooltip message -->
                  <v-tooltip left v-if="item.role.id !== 7 && item.active" :key="item.active">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-list-item @click="makeUserActive(item)" :disabled="item.id == user.id">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-toggle-switch-off-outline</v-icon>
                          <v-list-item-title class="ml-2">
                            {{ $t('buttons.deactivate') }}
                          </v-list-item-title>
                        </v-list-item>
                      </div>
                    </template>
                    <span v-if="item.id == user.id">{{ $t('tooltips.cannotDeactivate') }}</span>
                    <span v-else>{{ $t('tooltips.deactivate') }}</span>
                  </v-tooltip>

                  <v-tooltip left v-if="item.role.id !== 7 && !item.active" :key="item.active">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="makeUserActive(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-toggle-switch-outline</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.activate') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.activate') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-list-item @click="showDeleteUserModal(item)" :disabled="item.id == user.id">
                          <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                          <v-list-item-title class="ml-2">
                            {{ $t('buttons.delete') }}
                          </v-list-item-title>
                        </v-list-item>
                      </div>
                    </template>
                    <span v-if="item.id == user.id">{{ $t('tooltips.cannotDelete') }}</span>
                    <span v-else>{{ $t('tooltips.delete') }}</span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-dialog v-model="emailChangeModal" max-width="650">
        <v-card>
          <v-card-title class="info title white--text font-weight-bold">
            {{ $t('usersPage.emailChanged') }}
            <v-spacer />
            <v-icon class="mdi mdi-close" style="color: white" @click="closeEmailChangeModal"></v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <p>
                {{ $t('usersPage.verificationEmailSent') }}
              </p>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="primary" text @click="closeEmailChangeModal">
              {{ $t('buttons.ok') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteUserModal" max-width="650">
        <v-card>
          <v-card-title class="info title white--text font-weight-bold">
            {{ $t('usersPage.deleteUser') }}
            <v-spacer />
            <v-icon class="mdi mdi-close" style="color: white" @click="closeDeleteUserModal"></v-icon>
          </v-card-title>
          <v-card-text>
            <v-container>
              <p>
                {{ $t('usersPage.deleteMessage') }} <b>{{ userName }}</b
                >?
              </p>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn :disabled="!enableDelete" class="primary" text @click="deleteUser">
              {{ $t('buttons.yes') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogImage" height="auto" width="auto">
        <v-card>
          <v-img
            v-if="itemImage"
            :src="itemImage"
            max-height="600"
            max-width="600"
            style="background-size: cover"></v-img>
          <v-img
            v-else
            aspect-ratio="2"
            contain
            height="250px"
            src="@/assets/default-user.png"
            style="background-size: cover"
            width="250px"></v-img>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { masterUsersPageHeaders, usersPageHeaders } from '@/mixins/data-table-headers';
import { defaultUser } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { formatDateTime } from '@/utils/formatDate';
import { timeZones } from '@/mixins/time-zones';

export default {
  name: 'Users',
  props: ['saasPage', 'clientId'],
  components: { ButtonSubmit },
  data: () => ({
    searchString: '',
    dialog: false,
    headers: '',
    users: [],
    roles: [],
    formTitle: 'usersPage.newItem',
    formValid: true,
    editedItem: {},
    editedItemDeleted: {},
    deletedItem: {},
    editedIndex: -1,
    enableSave: true,
    enableDelete: true,
    deleteUserModal: false,
    emailChangeModal: false,
    userName: '',
    itemImage: null,
    dialogImage: false,
    loading: false,
    user: {
      id: '',
      name: '',
      email: '',
      role_id: null,
    },
    showPassword: false,
    timeZones: timeZones,
  }),
  created() {
    this.loadUser();
    this.editedItem = Object.assign({}, defaultUser);
    this.loadRoles();
    if (this.saasPage) {
      this.headers = 'master-user';
      this.loadAllSaasAdmins();
    } else {
      this.headers = 'user';
      this.loadAllUsers();
    }

    if (this.clientId) this.editedItem.role_id = 11;
  },
  computed: {
    clients() {
      return this.$store.getters['clients/getClients'];
    },
    masterUsersPageHeaders() {
      return masterUsersPageHeaders(i18n);
    },
    usersPageHeaders() {
      const hiddenColumns = this.clientId ? ['role.name'] : [];

      // Add additional hidden columns based on the user's role
      if (this.user.role_id !== 1 || this.clientId) {
        hiddenColumns.push('device', 'operating_system', 'browser');
      }

      return usersPageHeaders(i18n, { hiddenColumns });
    },
  },
  methods: {
    loadUser() {
      this.user = this.$store.getters['auth/user'];
    },
    async loadAllUsers() {
      if (this.clientId) {
        await this.$store.dispatch('users/getAllCustomers', this.clientId).then((res) => {
          this.users = res.data.map((e) => {
            e.active = parseInt(e.active);
            e.passengers_details_visible = parseInt(e.passengers_details_visible);
            return e;
          });
        });
      } else {
        await this.$store.dispatch('users/getAllUsers').then((res) => {
          this.users = res.data.map((e) => {
            e.active = parseInt(e.active);
            e.passengers_details_visible = parseInt(e.passengers_details_visible);
            e.last_active_at = formatDateTime(e.last_active_at);
            return e;
          });
        });
      }
    },
    async loadAllSaasAdmins() {
      await this.$store.dispatch('users/getAllSaasAdmins').then((res) => {
        this.users = res.data.map((e) => {
          e.active = parseInt(e.active);
          return e;
        });
      });
    },
    async loadRoles() {
      await this.$store.dispatch('roles/getAllRoles').then((res) => {
        this.roles = res.data.map((role) => ({
          ...role,
          name: this.getTranslatedRole(role),
          disabled: role.id === 11 && this.disabledForAffiliate ? true : false,
        }));
      });
    },

    editItem(item) {
      this.editedItem = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.role_id = parseInt(this.editedItem.role.id);
      this.editedItem.first_name = item.profile?.first_name || '';
      this.editedItem.last_name = item.profile?.last_name || '';
      this.editedItem.phone = item.profile?.phone;
      this.editedItem.percentage = parseInt(item.percentage);
      this.editedItem.organisation_id = parseInt(this.editedItem.organisation?.id);
      this.editedItem.oldEmail = item.email;
      this.editedItem.passengers_details_visible = item.passengers_details_visible;
      this.formTitle = `${this.editedItem.first_name} ${this.editedItem.last_name}`;
      this.editedItem.color = item.color && [2, 3, 7].includes(this.editedItem.role_id) ? item.color : '#000000';
      this.dialog = true;
    },

    deleteItem(item) {
      this.deletedItem = this.users.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.formTitle = item.name;
      this.dialog = true;
    },

    async save() {
      this.enableSave = false;
      this.loading = true;
      let formData = this.createFormData();

      await this.$store
        .dispatch('users/saveUser', formData)
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          if (this.saasPage) {
            this.loadAllSaasAdmins();
          } else {
            this.loadAllUsers();
            this.$store.dispatch('users/fetchAllDrivers');
          }
          if (this.editedItem.email !== this.editedItem.oldEmail && this.editedItem.id) {
            this.emailChangeModal = true;
          }
          this.close();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            if (error.response.data.errors?.username || error.response.data.errors?.email) {
              if (error.response.data.errors?.username && error.response.data.errors?.username[0] === 'USER-0013') {
                this.$refs.usernameRef.applyResult({
                  errors: [
                    i18n.t('customValidations.fieldUnique', {
                      field: i18n.t('names.username'),
                    }),
                  ],
                });
              }
              if (error.response.data.errors?.email && error.response.data.errors?.email[0] === 'USER-0012') {
                this.$refs.emailRef.applyResult({
                  errors: [
                    i18n.t('customValidations.fieldUnique', {
                      field: i18n.t('names.email'),
                    }),
                  ],
                });
              }
            } else {
              this.$store
                .dispatch('errorMessages/errorMapper', error.response.data.message)
                .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'yellow darken-3' }));
            }
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enableSave = true;
          this.loading = false;
          this.formTitle = 'usersPage.newItem';
        });
    },

    async deleteUser() {
      this.enableDelete = false;

      await this.$store
        .dispatch('users/deleteUser', this.editedItemDeleted)
        .then((res) => {
          if (res.success) {
            if (this.saasPage) {
              this.loadAllSaasAdmins();
            } else {
              this.loadAllUsers();
              this.$store.dispatch('users/fetchAllDrivers');
            }
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
            this.closeDeleteUserModal();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.enableDelete = true;
        });
    },

    createFormData() {
      let formData = new FormData();
      if (this.editedItem.image) {
        formData.append('image', this.editedItem.image);
      }
      if (this.editedItem.id) {
        formData.append('_method', 'POST');
        formData.append('id', this.editedItem.id);
      } else {
        formData.append('id', '');
      }

      // Check if the selected role is 'External Associate' with role_id 7
      if (this.editedItem.role_id === 7) {
        // Generate a timestamp suffix to ensure unique usernames and passwords
        const timestamp = Date.now();
        // Check if this is an existing user (indicated by presence of an ID)
        if (this.editedItem.id) {
          // Check if the current role is different from 'External Associate'
          if (this.editedItem.role.id !== 7) {
            // Changing the role to 'External Associate', generate new credentials
            formData.append('password', `external_associate_password_${timestamp}`);
            formData.append('username', `external_associate_username_${timestamp}`);
          } else {
            // Updating an existing 'External Associate', reuse old credentials if they exist
            this.editedItem.password && formData.append('password', this.editedItem.password);
            this.editedItem.username && formData.append('username', this.editedItem.username);
          }
        } else {
          // Creating a new 'External Associate', generate new credentials
          formData.append('password', `external_associate_password_${timestamp}`);
          formData.append('username', `external_associate_username_${timestamp}`);
        }
      } else {
        // If the user is not an 'External Associate', use the provided credentials
        this.editedItem.password && formData.append('password', this.editedItem.password);
        this.editedItem.username && formData.append('username', this.editedItem.username);
      }

      let user = this.$store.getters['auth/user'];

      formData.append(
        'organisation_id',
        this.editedItem.organization_id ? this.editedItem.organization_id : user.organisation.id
      );
      formData.append('first_name', this.editedItem.first_name);
      formData.append('last_name', this.editedItem.last_name);
      formData.append('phone', this.editedItem.phone);
      formData.append('email', this.editedItem.email ? this.editedItem.email : '');
      formData.append('role_id', this.editedItem.role_id ? this.editedItem.role_id : 1);
      [2, 3, 7].includes(this.editedItem.role_id) &&
        formData.append('color', this.editedItem.color ? this.editedItem.color : '#000000');
      formData.append('percentage', this.editedItem.percentage ? this.editedItem.percentage : '');
      formData.append('passengers_details_visible', this.editedItem.passengers_details_visible ? 1 : 0);
      this.clientId && formData.append('client_id', this.clientId ? this.clientId : '');
      this.editedItem.client_id &&
        this.editedItem.role_id == 11 &&
        formData.append('client_id', this.editedItem.client_id ? this.editedItem.client_id : '');
      formData.append('timezone', this.editedItem.timezone ? this.editedItem.timezone : '');

      return formData;
    },

    async makeUserActive(item) {
      await this.$store
        .dispatch('users/changeUserActiveStatus', item)
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.activeStatusChanged'), color: 'green' });
          if (this.saasPage) {
            this.loadAllSaasAdmins();
          } else {
            this.loadAllUsers();
          }
          this.$store.dispatch('users/fetchAllDrivers');
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultUser);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.formTitle = 'usersPage.newItem';
    },

    showDeleteUserModal(item) {
      this.editedItemDeleted = item;
      this.userName = item.profile?.first_name;
      this.deleteUserModal = true;
    },

    closeDeleteUserModal() {
      this.deleteUserModal = false;
    },

    closeEmailChangeModal() {
      this.emailChangeModal = false;
    },

    showProfilePicture(item) {
      this.dialogImage = true;
      if (item.profile?.picture_path) {
        if (
          item.profile?.picture_path &&
          !item.profile?.picture_path.includes('https://') &&
          !item.profile?.picture_path.includes('http://')
        ) {
          this.itemImage = process.env.VUE_APP_BACKEND_URL.concat(item.profile?.picture_path);
          this.itemImage = this.itemImage.replace('public', 'storage');
        } else {
          this.itemImage = item.profile?.picture_path;
        }
      } else {
        this.itemImage = null;
      }
    },

    getTranslatedRole(role) {
      const roleTranslations = {
        1: 'usersRoles.masterAdmin',
        2: 'usersRoles.manager',
        3: 'usersRoles.driver',
        4: 'usersRoles.operator',
        7: 'usersRoles.externalAssociate',
        8: 'usersRoles.sales',
        9: 'usersRoles.salesLead',
        10: 'usersRoles.gnet',
        11: 'usersRoles.customer',
        12: 'usersRoles.publicUser',
        13: 'usersRoles.zapier',
      };

      return roleTranslations[role.id] ? i18n.t(roleTranslations[role.id]) : '';
    },

    togglePassword() {
      this.showPassword = !this.showPassword;
    },

    addCustomClass(item) {
      return item.active === true || item.active == 1 ? 'active-row' : 'inactive-row';
    },
  },

  watch: {
    dialog(val) {
      if (val) {
        if (this.clientId) {
          this.editedItem.role_id = 11;
        }
      } else {
        this.close();
      }
    },
    '$i18n.locale': {
      handler() {
        this.loadRoles();
      },
    },
    clientId: {
      handler() {
        this.editedItem.role_id = 11;
        this.loadAllUsers();
      },
    },
  },
};
</script>

<style scoped>
.line {
  color: rgba(0, 0, 0, 0.42);
}
</style>
